import React from "react";
import { Newsletters } from "../data/newsletters.js";
import Checkbox from "./Checkbox";

class NewsletterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedItems: new Map(),
      showIvy: false,
      affl: "bad",
      email: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleAfflChange = this.handleAfflChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const id = e.target.id;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(id, isChecked),
    }));
    if (id === "ivyeyes" && isChecked) {
      //this.setState({showIvy: true});
      alert(
        "If Ivy Eyes is checked when you click submit, you will be redirected to the Ivy Eyes website to complete the sign up process."
      );
    } else if (id === "ivyeyes" && !isChecked) {
      //this.setState({showIvy: false});
    }
  }

  handleAfflChange(e) {
    const affl = e.target.value;
    if (
      affl === "student" ||
      affl === "prospective_student" ||
      affl === "parent" ||
      affl === "prospective_parent" ||
      affl === "alum" ||
      affl === "faculty/staff" ||
      affl === "other"
    ) {
      this.setState({ affl: affl });
    } else {
      this.setState({ affl: "bad" });
    }
  }

  handleEmailChange(e) {
    const email = e.target.value;
    this.setState({ email: email });
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.state.affl === "bad") {
      alert(
        "Please select a Princeton University affiliation from the dropdown."
      );
      return;
    }

    // daily subscribers requires affiliation
    const dailyNewsId = "314837ec05";
    let dailyNewsToggled = false;

    // ivy eyes requires redirect
    const ivyEyesId = "ivyeyes";
    let ivyEyesToggled = false;

    const array = Array.from(this.state.checkedItems, ([id, isChecked]) => ({
      id,
      isChecked,
    })).filter((obj) => obj.isChecked);
    let elementIds = [];

    for (let elem of array) {
      if (elem.id === dailyNewsId) {
        dailyNewsToggled = true;
      } else if (elem.id === ivyEyesId) {
        ivyEyesToggled = true;
      } else {
        elementIds.push(elem.id);
      }
    }

    const obj = {
      user_email: this.state.email,
      newsletter_ids: elementIds,
      demographics: {
        "affiliation": this.state.affl
      }
    };

    var fetchData = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };

    fetch(
      "https://dailyprince-subscribe-api.herokuapp.com/subscribe",
      fetchData
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data.success);
        if (data.success === "unsuccessful") {
          this.setState({
            callback:
              "Something went wrong. Have you already subscribed to this service? Check your inbox or contact tech@dailyprincetonian.com.",
          });
          this.setState({ callbackStatus: "red" });
        } else {
          this.setState({
            callback:
              "Success! If applicable, you will be redirected to the Ivy Eyes website in a few seconds.",
          });
          this.setState({ callbackStatus: "green" });
        }
      });

    if (dailyNewsToggled) {
      const objAffl = {
        user_email: this.state.email,
        affiliation: this.state.affl,
        newsletter_ids: [dailyNewsId],
      };

      var fetchDataAffl = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(objAffl),
      };

      fetch(
        "https://dailyprince-subscribe-api.herokuapp.com/subscribe/affiliation",
        fetchDataAffl
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data.success);
          if (
            data.success === "unsuccessful" ||
            this.state.callbackStatus === "red"
          ) {
            this.setState({
              callback:
                "Something went wrong. Have you already subscribed to this service? Check your inbox or contact tech@dailyprincetonian.com.",
            });
            this.setState({ callbackStatus: "red" });
          } else {
            this.setState({
              callback:
                "Success! If applicable, you will be redirected to the Ivy Eyes website in a few seconds.",
            });
            this.setState({ callbackStatus: "green" });
          }
        });
    }

    if (ivyEyesToggled && this.state.callbackStatus !== "red") {
      setTimeout(function () {
        window.location.href = "http://ivyeyes.dailyprincetonian.com";
      }, 7000);
    }
  }

  render() {
    const { callback, callbackStatus } = this.state;
    return (
      <div className="subscription-form">
        <h1 style={{ textAlign: "center" }}>SUBSCRIBE  for reliable, timely, and truthful news that matters</h1>
        <br />
        <h3>
          Select each newsletter you’d like to receive.
        </h3>
        <form onSubmit={this.handleSubmit}>
          {Newsletters.map((item) => (
            <label key={item.key}>
              <Checkbox
                name={item.name}
                checked={this.state.checkedItems.get(item.id)}
                onChange={this.handleChange}
                id={item.id}
              />
              &emsp;{item.name}
              <br /> <p className="description">&emsp;{item.description} </p>
              <hr />
            </label>
          ))}
          <input
            className="subscribe"
            type="email"
            name="email"
            placeholder="Email Address"
            onChange={this.handleEmailChange}
            required
          />
          <select
            className="affl"
            id="affl"
            name="affl"
            onChange={this.handleAfflChange}
            style={{ color: this.state.affl === "bad" ? "#757575" : "inherit" }}
          >
            <option value="" disabled selected>
              Princeton University Affiliation
            </option>
            <option value="student">Student</option>
            <option value="prospective_student">Prospective Student</option>
            <option value="parent">Parent</option>
            <option value="prospective_student">Prospective Parent</option>
            <option value="alum">Alum</option>
            <option value="faculty/staff">Faculty/Staff</option>
            <option value="other">Other</option>
          </select>
          <div id="button">
            <input className="subscribe" type="submit" value="Subscribe" />
          </div>
          <p
            className="small"
            style={{ display: this.state.showIvy ? "block" : "none" }}
          >
            * If Ivy Eyes is checked when you click submit, you will be
            redirected to the Ivy Eyes website to complete the sign up process
            for legal reasons.
          </p>
          <div id="callback-status" className={callbackStatus}>
            {callback}
          </div>
          <hr />
          <div className="tiny-links">
            <p>
              If you encounter problems, manually subscribe to our newsletters
              using the following links:
            </p>
            {Newsletters.map((item) => (
              <>
                <a href={item.url} target="_blank" className="tiny-link">
                  {item.name}
                </a>
                <br />
              </>
            ))}
          </div>
        </form>
      </div>
    );
  }
}

export default NewsletterForm;
