import React from "react";
import "react-dropdown/style.css";

function PrintSubscriptionForm() {

  const onCampusPaymentLink = "https://buy.stripe.com/cN2eXb2Dd6sMaT6bJH";
  const offCampusPaymentLink = "https://buy.stripe.com/7sIbKZ4Ll6sMaT6296";

  return (
    <div className="welcome-tile">
      <a
        href="https://www.dailyprincetonian.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://assets.dailyprincetonian.com/common/banner-transparent.png"
          alt="banner"
          className="logo-image"
        ></img>
      </a>
      <h1 className="heading">
        <span className="highlight">Princeton</span> <br></br>Delivered to{" "}
        <br></br>your Doorstep
      </h1>
      <br />
      <div className="text">
        <p>
          <a>Subscribe</a> by <b>August 30th</b> to get our newspaper delivered
          to you!
        </p>
        <p className="extra-info">
          **Please note we do not support international deliveries**
        </p>
      </div>
      <h1 className="form-head">
        Subscribe Now <br />
        for <span className="highlight">One Semester</span> (11 Weeks)
      </h1>
      <br></br>
      <p className="text print-dates">
        <b>Print Dates:</b>&nbsp; Sept.&nbsp;6, Sept.&nbsp;13, Sept.&nbsp;20, Sept.&nbsp;27, Oct.&nbsp;4, Oct.&nbsp;25, Nov.&nbsp;1,
        Nov.&nbsp;8, Nov.&nbsp;15, Nov.&nbsp;22, Dec.&nbsp;6
      </p>
      <br></br>
      <div className="subscription-options">
        <div className="options">
          <div className="tile">
            <span className="option-name">On Campus Orders</span>
            <span
              onClick={() => window.open(onCampusPaymentLink)}
              className="subscribe-btn"
            >
              Subscribe Here
            </span>
          </div>
          <div className="tile">
            <span className="option-name">Off Campus Orders</span>
            <span
              onClick={() => window.open(offCampusPaymentLink)}
              className="subscribe-btn"
            >
              Subscribe Here
            </span>
          </div>
          <div style={{ textAlign: "center" }} className="tile">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span className="option-name">
                Looking to Order More than 5 Copies?
              </span>
              <br></br>
              <span className="option-price-sem">
                Contact the 'Prince' Business Team at <br />
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:subscriptions@dailyprincetonian.com"
                >
                  <b className="link">subscriptions@dailyprincetonian.com</b>
                </a>
              </span>
            </div>
          </div>
          <div style={{ textAlign: "center" }} className="tile">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span className="option-name">Local Bundle</span>
              <br></br>
              <span className="option-price-sem">
                For businesses, libraries, and other public spaces
              </span>
              <br></br>
              <span className="option-price-sem">
                Contact us for customized bundles:
              </span>
              <span className="option-price-sem">- 20 copies</span>
              <span className="option-price-sem">- 50 copies</span>
              <span className="option-price-sem">
                - separate amount to fit your needs
              </span>
              <br></br>
              <span className="option-price-sem">
                Contact the 'Prince' Business Team at <br />
                <a
                  style={{ textDecoration: "none" }}
                  href="mailto:subscriptions@dailyprincetonian.com"
                >
                  <b className="link">subscriptions@dailyprincetonian.com</b>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrintSubscriptionForm;
