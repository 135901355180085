import React from "react";
import NewsletterForm from "./components/NewsletterForm.jsx";
import PrintSubscriptionForm from "./components/PrintSubscriptionForm.jsx";
import "./App.css";

function App() {
  return (
    <>
        <div className="subscription-forms">
          <PrintSubscriptionForm />
        </div>
    </>
  );
}

export default App;
