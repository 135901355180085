export const Newsletters = [{
    'id': '314837ec05',
    'name': 'Daily Newsletter',
    'description': 'Keep up to date with all of The Daily Princetonian’s coverage, delivered weekdays.',
    'url': 'http://eepurl.com/GveCn'
}, {
    'id': '18302952dc',
    'name': 'Intersections',
    'description': 'A guide to arts and culture inside and around Princeton, delivered every Thursday from The Prospect.',
    'url': 'http://eepurl.com/hiCd2r'
}, {
    'id': 'ivyeyes',
    'name': 'Ivy Eyes',
    'description': 'The top stories from each Ivy League newspaper, curated, and delivered to your inbox on Sundays.',
    'url': 'https://ivyeyes.dailyprincetonian.com'
}]
